.contact {
    background-color: #333;
    max-width: 100%;
    border-top: 5px solid black;
}

.contact h1 {
    margin: 0;
    border-bottom: 5px solid black;
}

.contact_details {
    text-align: left;
}

.contact_details h2 {
    margin: 0;
}

.contact_details p {
    margin: 2px 0 25px;
}

.contact_details a {
    color: white;
    text-decoration: underline;
}

.details td {
    padding-left: 10px;
}
.contact_form {
    text-align: left;
}
input[type=text], input[type=email], textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

textarea {
    height: 150px;
}

input[type=submit] {
    background-color: #ac9d39;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .contact {
        max-width: 50vw;
        margin: auto;
    }

    .details {
        margin: auto;
    }

}
