.home {
    background-color: #333;
    max-width: 100%;
}

.carousel_container {
    width: 100%;
    text-align: center;
}

.carousel_content {
    position: relative;
}

.carousel_back, .carousel_next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translate(-50%);
    height: 100%;
    opacity: 0;
    border: 0;
    width: 10%;
    outline: none;
    transition: .5s ease;
}

.carousel_back {
    left: 0;
}

.carousel_next {
    right: 0;
}

.carousel_content:hover .carousel_back, .carousel_content:hover .carousel_next {
    opacity: 0.5;
}


.about_list_container {
    text-align: center;
}

.about_list {
    display: inline-block;
    text-align: left;
}

.logo {
    padding-right: 25px;
}

h1 {
    font-size: 32px;
}

table {
    margin: auto;
}

ul {
    text-align: left;
}

.gallery_links a {
    color: white;
    text-decoration: underline;
}

@media only screen and (min-width: 768px) {
    .home {
        max-width: 50vw;
        margin: auto;
    }

    .carousel_back, .carousel_next {
        width: 5%;
    }

    h1 {
        font-size: 72px;
        margin: 0;
    }

    .carousel_container {
        width: 75%;
        margin: auto;
    }
}

@media only screen and (max-width: 956px) and (min-width: 768px) {
    h1 {
        font-size: 32px;
    }
}