.gallery_separator {
    background-color: black;
}

.gallery_template {
    background-color: #333;
    max-width: 100%;
}

.image_padding {
    padding: 5px;
}

.image_sm {
    padding: 5px;
    width: 90%;
    background-color: white;
    cursor: pointer;
}

.modal {
    position: fixed;
    z-index: 1;
    padding-top: 75px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.9);
}

.modal-content {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

.close {
    position: fixed;
    top: 15px;
    right: 35px;
    font-size: 40px;
    transition: 0.3s;
    cursor: pointer;
}

@media only screen and (min-width: 768px) {
    .gallery_separator {
        padding: 5px;
    }

    .carousel_container {
        width: 75%;
        margin: auto;
    }

    .title {
        font-size: 3em;
    }

    .image_sm {
        width: 45%;
    }
}

@media only screen and (max-width: 768px){
    .modal-content {
        width: 100%;
    }
}