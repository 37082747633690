.gallery {
    background-color: #333;
    max-width: 100%;
}

.image_links img {
    max-width: 100%;
}

.image_links {
    margin: 5px;
    float: left;
    position: relative;
}

.image_description {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    transition: .5s ease;
    background-color: #333333;
}

.text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

@media only screen and (min-width: 768px) {
    .gallery {
        max-width: 50vw;
        margin: auto;
    }
    .image_links img {
        max-width: 24vw;
    }

    .image_description {
        opacity: 0;
        transition: .5s ease;
        background-color: #333333;
    }

    .image_links:hover .image_description {
        opacity: 0.75;
    }
}