.navbar {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #333;
}

.navbar li {
    float: none;
}

.navbar li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 16px;
}

.navbar a:hover {
    background-color: #ddd;
    color: black;
}

.navbar a.active {
    background-color: #fa8939;
    color: black;
}

.gallery_dropdown a {
    background-color: #282c34;
}

.links {
    display: none;
}

.show {
    display: block;
}

@media only screen and (min-width: 768px) {
    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .navbar li {
        float: left;
    }

    .gallery {
        display: inline-block;
    }

    .gallery_dropdown {
        text-align: left;
        display: none;
        position: absolute;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
    }

    .gallery:hover .gallery_dropdown{
        display: block;
    }

    .links {
        display: block;
    }

    .hamburger_menu {
        display: none;
    }
}